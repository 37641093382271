import { useParams } from "react-router-dom";
import Flow1 from "./Flow1";
import Flow2 from "./Flow2";
import Flow3 from "./Flow3";

const CareerPage = ({ flow }) => {
  const params = useParams();
  const careerId = params.id;

  return (
    <div
      // keep this container flex, so that we can flex-grow its children
      className="d-flex flex-column"
    >
      {/* main container */}
      {flow === 1 && <Flow1 />}
      {flow === 2 && <Flow2 />}
      {flow === 3 && <Flow3 careerId={careerId} />}
    </div>
  );
};

export default CareerPage;
