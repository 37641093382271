import axios from "axios";
import React from "react";
import { Form } from "react-bootstrap";
import styles from "../../components/NavbarComponent/NavbarComponent.module.scss";
import { COLORS } from "../../constants/constants";
import contents from "../../contents";
import { sendEmail } from "../../emailjs";
const { PARTNER_WITH_US_PAGE: { SECTION_1, SECTION_2 } } = contents;

export default function PartnerPage() {

  const [submitting, setSubmitting] = React.useState(false);
  const formRef = React.useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    //  form data
    const formData = new FormData(e.target);
    const data = {};
    formData.forEach((value, key) => data[key] = value);

    axios.post("https://sheet2api.com/v1/8DiE5jMBTMqM/soaron-partnership-form", data)
      .then((res) => {
        alert("Thank you for your interest! We will get back to you soon.");
        sendEmail({
          subject: "Partner with us",
          message: `There is a new entry for partnership form: \n Name: ${data["Name"]} \n Email: ${data["Email"]} \n Phone: ${data["Phone"]} \n Company: ${data["Company"]} \n Message: ${data["Message"]}`,
          link: 'https://docs.google.com/spreadsheets/d/11Vzddn9wF2VkZ5eqq1vx3FU8J04KN-qMrxOawDBfJiQ/edit#gid=0'
        });
        setSubmitting(false);
        formRef.current.reset();
      })
      .catch((err) => {
        setSubmitting(false);
        console.log(err);
        alert("Form submission is closed for now. Please try again later.");
        return;
      })

    console.log(data);
  }

  return <>
    <div
      style={{
        backgroundColor: COLORS.DARK2,
        backgroundPosition: 'center',
        backgroundSize: 'cover', color: "white", backgroundBlendMode: 'darken'
      }}
      className="sectionContainer align-items-md-center pt-md-5"
    >
      <div className="main-box text-light d-flex flex-column text-center">
        <div
          className="fw-bold poppins-bold display-3" style={{ marginBottom: '2rem' }}
        >
          {SECTION_1.TITLE}
        </div>

        {
          SECTION_1.DESCRIPTION.split('\n').map((item, index) => {
            return <p key={index} className="mx-auto"
              style={{
                fontFamily: "Quicksand",
                fontSize: "20px",
                marginBottom: "25px",
                maxWidth: "1000px",
              }}>
              {item}
            </p>
          }
          )
        }
      </div>

    </div>
    <div className="sectionContainer py-5">
      <div className="main-box d-flex flex-column text-center" style={{ maxWidth: 1400 }}>
        <div className="h3 fw-bold">
          {SECTION_2.FORM.TITLE}
        </div>
        <br />
        <div className="p">
          {SECTION_2.FORM.DESCRIPTION}
        </div>
        <br />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="row mt-3">
            {
              SECTION_2.FORM.INPUTS.map((item, index) => {
                return <div key={index} className={`col-md-${item.COL} mb-3`}>
                  <Form.Group className={styles.formGroup}>
                    <div className="text-start fw-bold">
                      <Form.Label className={`${styles.formLabel}`}>
                        {item.LABEL} <span className="text-danger">*</span>
                      </Form.Label>
                    </div>
                    {
                      item.TYPE === 'textarea' ?
                        <Form.Control
                          name={item.LABEL}
                          required={item.REQUIRED}
                          as={item.TYPE}
                          rows={4}
                          className={styles.formControl}
                        />
                        :
                        <Form.Control
                          name={item.LABEL}
                          required={item.REQUIRED}
                          type={item.TYPE}
                          className={styles.formControl}
                        />
                    }
                  </Form.Group>
                </div>
              })
            }
            <div className="col-md-12 text-center mt-4">
              <button className="btn btn-dark btn-lg px-5" style={{ width: 300, fontSize: 16 }}>
                {submitting ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </>
}
