import { CONTENT_TYPES } from "../constants/constants";

export const REFUND_POLICY = [
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        value: [
            'Customers can cancel their booking without any charges if the operator (FLYTOR) has not initiated the service or started his/her journey to your location.',
            'If the operator (FLYTOR) has initiated the journey to your location, a cancellation charge of Rs.100 or 7% of the booking amount, whichever is higher, will be applied.'
        ]
    },
    {
        type: CONTENT_TYPES.SUB_HEADING,
        level: 1,
        value: 'Cancellation Restrictions'
    },
    {
        type: CONTENT_TYPES.TEXT,
        level: 1,
        value: 'Cancellation of the service cannot be done once the operator (FLYTOR) has reached your location or initiated the service.'
    },
    {
        type: CONTENT_TYPES.SUB_HEADING,
        level: 1,
        value: 'Refund Policy:'
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 1,
        value: [
            "If the booking is canceled by the customer within 12 hours of placing the order, SOARON Aerospace Innovations Pvt Ltd will initiate an instant refund. The refund amount will be the booking amount minus a service charge of 7%.",
            "The refund will be credited to the customer's source account or chosen method of refund within 7-14 working days.",
            "If the booking is canceled by the Flytor (operator), SOARON Aerospace Innovations Pvt Ltd will initiate a full refund instantly.",
            "The refund will be credited to the customer's source account or chosen method of refund within 7-14 working days, provided that the amount has already been paid by the customer."
        ]

    },
    {
        type: CONTENT_TYPES.TEXT,
        level: 1,
        value: 'For any information or queries you can contact'
    },
    {
        type: CONTENT_TYPES.SUB_HEADING,
        level: 1,
        value: 'SOARON AEROSPACE INNOVATIONS PVT LTD'
    },
    {
        type: CONTENT_TYPES.TEXT,
        level: 1,
        value: '10-1-128/1/2D, Ali Manor Complex, 2nd Floor,'
    },
    {
        type: CONTENT_TYPES.TEXT,
        level: 1,
        value: 'Masab Tank, Hyderabad, Telangana 500028,'
    },
    {
        type: CONTENT_TYPES.TEXT,
        level: 1,
        value: 'e-mail: Support@soaron.co'
    },

]