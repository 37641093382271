import React from 'react';
import PnTCard from '../../components/PnTCard';
import { COLORS } from '../../constants/constants';
import contents from '../../contents';

const {
    PATENT_AND_TRADEMARK_PAGE: { SECTION_1, SECTION_2 },
} = contents;

export default function PatentAndTrademarkPage() {
    return (
        <>
            <div className='main-box py-5 my-md-3'>
                <div className='row flex-column-reverse flex-md-row justify-content-between'>
                    <div className='col-12 col-md-5 p-3'>
                        <div className='h2 fw-bold mt-3'>{SECTION_1.TITLE}</div>
                        <div className='p mt-2'>{SECTION_1.DESCRIPTION}</div>
                    </div>
                    <div className='col-12 col-md-5'>
                        <PnTCard
                            image={SECTION_1.IMAGE}
                            logo={'assets/Black.png'}
                            numberText={'Application No.'}
                            number={SECTION_1.NUMBER}
                            status={SECTION_1.STATUS}
                        />
                    </div>
                </div>
            </div>
            <div
                className='main-box py-5 my-md-3'
                style={{ backgroundColor: COLORS.LIGHT }}
            >
                <div className='row justify-content-between'>
                    <div className='col-12 col-md-5'>
                        <PnTCard
                            image={SECTION_2.IMAGE}
                            logo={'assets/Black.png'}
                            numberText={'Trademark No.'}
                            number={SECTION_2.NUMBER}
                            status={SECTION_2.STATUS}
                        />
                    </div>
                    <div className='col-12 col-md-5 p-3'>
                        <div className='h2 mt-3 fw-bold'>{SECTION_2.TITLE}</div>
                        <div className='p mt-2'>{SECTION_2.DESCRIPTION}</div>
                    </div>
                </div>
            </div>
        </>
    );
}
