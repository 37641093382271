import React from "react";
import contents from "../../contents";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { Link } from "react-router-dom";
import { COLORS, ROUTES } from "../../constants/constants";
import styles from './CareerPage.module.scss'

const {
    CAREER_PAGE: { SECTION_1 },
} = contents;

export default function Flow1() {
    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center text-center"
            style={{ height: "90vh", backgroundColor: COLORS.DARK2 }}>
            <div
                className="fw-bold poppins-bold display-3 text-light" style={{ marginBottom: '2rem' }}
            >
                {SECTION_1.TITLE}
            </div>

            <span
                style={{
                    color: "white",
                    fontFamily: "Quicksand",
                    fontSize: "32px",
                    marginBottom: "35px",
                }}>
                {SECTION_1.SUBTITLE}
            </span>

            <p
                style={{
                    color: "white",
                    fontFamily: "Quicksand",
                    fontSize: "20px",
                    marginBottom: "25px",
                    width: "1000px",
                    maxWidth: "95%",
                }}>
                {SECTION_1.DESCRIPTION}
            </p>

            <span
                style={{
                    color: "white",
                    fontFamily: "Quicksand",
                    fontSize: "20px",
                    marginBottom: "57px",
                    display: 'flex',
                    gap: '10px',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}>
                {SECTION_1.CTA}{" "}
                <a
                    href={`mailto:${SECTION_1.EMAIL}`}
                    style={{
                        // color: "white",
                        fontFamily: "Quicksand",
                        fontSize: "20px",
                        textDecoration: "none",
                        fontWeight: "bold",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '5px',
                    }}>
                    <FontAwesomeIcon icon={faEnvelope} size="sm" />  {SECTION_1.EMAIL}
                </a>
            </span>

            <div className="d-flex flex-column">
                {/* <div
                    className="d-flex align-items-center"
                    style={{
                        borderRadius: '14px',
                        backgroundColor: '#505050',
                        paddingInline: '20px',
                        paddingBlock: '10px',
                        marginBottom: '25px',
                        minHeight: '60px',
                        minWidth: '380px'
                    }}
                >
                    <div
                        className="d-flex flex-column align-items-between w-100"
                    >
                        <span
                            style={{
                                color: '#9A9A9A',
                                fontFamily: 'poppins',
                                fontSize: '12px',
                                marginBottom: '4px',
                                textAlign: 'left'
                            }}
                        >
                            Search Careers
                        </span>
                        <input
                            type={"text"}
                            style={{
                                color: '#C2C2C2',
                                fontFamily: 'poppins',
                                fontSize: '16px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: 'transparent',
                            }}
                        />
                    </div>
                    <FontAwesomeIcon
                        icon={faSearch}
                        color={'#C2C2C2'}
                        style={{
                            width: '15px',
                            height: '15px',
                        }}
                    />
                </div>

                <div
                    className="d-flex align-items-center"
                    style={{
                        borderRadius: '14px',
                        backgroundColor: '#505050',
                        paddingInline: '20px',
                        paddingBlock: '10px',
                        marginBottom: '63px',
                        minHeight: '60px',
                        minWidth: '380px'
                    }}
                >
                    <select defaultValue={"0"}
                        className="d-flex flex-column align-items-start w-100"
                        style={{
                            color: '#C2C2C2',
                            fontFamily: 'poppins',
                            fontSize: '12px',
                            marginBottom: '4px',
                            backgroundColor: 'transparent',
                            border: 'none',
                        }}
                    >

                        <option
                            value="0"
                            style={{
                                color: '#C2C2C2',
                                fontFamily: 'poppins',
                                fontSize: '12px',
                                marginBottom: '4px',
                                backgroundColor: 'transparent',
                            }}
                        >
                            Browse Catergories
                        </option>

                        {
                            options.map((option, index) => {
                                return (
                                    <option
                                        value={option.value}
                                        key={index}
                                        style={{
                                            color: '#C2C2C2',
                                            fontFamily: 'poppins',
                                            fontSize: '12px',
                                            marginBottom: '4px',
                                            backgroundColor: 'transparent',
                                        }}
                                    >
                                        {option.title}
                                    </option>
                                )
                            })
                        }


                    </select>

                </div> */}

                {/* contains two buttons */}
                <div className="d-flex justify-content-between gap-4">
                    <Link to={ROUTES.CAREER_JOBS}>
                        <button className={styles.customButton}>
                            View Jobs
                        </button>
                    </Link>
                    {/* <Link to="/career/jobs">
                        <button className={styles.customButton}>
                            View Internships
                        </button>
                    </Link> */}
                </div>
            </div>
        </div>
    );
}
