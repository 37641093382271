import styles from "./CareerPage.module.scss";
import React from "react";
import jobsData from "./jobs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function Flow3({ careerId }) {
  const [job, setJob] = React.useState(null);

  React.useEffect(() => {
    setJob(jobsData.filter((job) => job.id.toString() === careerId)[0]);
  }, [careerId]);

  console.log(job, careerId);

  return (
    <>
      {job && (
        <div
          className="d-flex flex-column flex-grow-1 px-2"
          style={{
            backgroundColor: "#0F151A",
          }}
        >
          <div
            className="container d-flex flex-column align-items-center flex-grow-1"
            style={{
              paddingBlock: "72px",
            }}
          >
            <div
              className="d-flex justify-content-between align-items-center w-100 mb-5"
            >
              <span
                className={`fw-bold h1`}
                style={{
                  color: "white",
                  fontFamily: "poppins",
                  width: "60%"
                }}
              >
                {job.title}
              </span>

              <button
                className={styles.customButton}
                onClick={() => window.scrollTo(0, document.body.scrollHeight)}
              >
                Apply now
              </button>
            </div>

            <span
              className="w-100 text-start"
              style={{
                color: "#C2C2C2",
                fontFamily: "poppins",
                fontSize: "16px",
                marginBottom: "12px",
                whiteSpace: "pre",
              }}
            >
              {`Location:\t\t\t\t${job.location}`}
            </span>

            <span
              className="w-100 text-start"
              style={{
                color: "#C2C2C2",
                fontFamily: "poppins",
                fontSize: "16px",
                marginBottom: "12px",
                whiteSpace: "pre",
              }}
            >
              {`Job Posted:\t\t\t\t${job.date}`}
            </span>

            <span
              className="w-100 text-start"
              style={{
                color: "#C2C2C2",
                fontFamily: "poppins",
                fontSize: "16px",
                marginBottom: "40px",
                whiteSpace: "pre",
              }}
            >
              {`Employment Type:\t\t${job.type}`}
            </span>

            <div
              className="w-100 text-start fw-bold"
              style={{
                color: "#C2C2C2",
                fontFamily: "quicksand",
                fontSize: "16px",
                marginBottom: "20px",
                whiteSpace: "pre",
              }}
            >
              {job.tagline}
            </div>

            <span
              className="w-100 text-start"
              style={{
                color: "#C2C2C2",
                fontFamily: "quicksand",
                fontSize: "16px",
                marginBottom: "40px",
                whiteSpace: "pre",
              }}
            >
              {job.description}
            </span>

            {job.jobDetails.map((detail) => {
              return (
                <div
                  className="d-flex flex-column w-100"
                  style={{
                    marginBottom: "32px",
                  }}
                >
                  <span
                    className="fw-bold"
                    style={{
                      color: "white",
                      fontFamily: "poppins",
                      fontSize: "24px",
                      marginBottom: "20px",
                      marginTop: "30px",
                    }}
                  >
                    {detail.title.toUpperCase() + ":"}
                  </span>
                  {detail.description.map((row) => {
                    return (
                      <div
                        className="d-flex align-items-start"
                        style={{
                          color: "#C2C2C2",
                          fontFamily: "quicksand",
                          fotSize: "20px",
                          whiteSpace: "pre",
                          lineHeight: "32px",
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{
                            fontSize: 12,
                          }}
                        >
                          <FontAwesomeIcon style={{
                            marginTop: 12
                          }} className="mx-3" size="xs" icon={faCircle} /> {" "}
                        </div>
                        <div className="my-auto text-wrap">{row}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}

            <div
              className="d-flex flex-column w-100"
              style={{
                marginBottom: "32px",
              }}
            >
              <span
                className="fw-bold"
                style={{
                  color: "white",
                  fontFamily: "poppins",
                  fontSize: "24px",
                  marginBottom: "56px",
                }}
              >
                HOW TO APPLY?
              </span>

              <div
                className="text-wrap"
                style={{
                  color: "#C2C2C2",
                  fontFamily: "quicksand",
                  fontSize: "20px",
                  marginBottom: "30px",
                  whiteSpace: "pre",
                }}
              >
                {`     Does this role sound like a good fit? Email us at: `}{" "}
                <a href="mailto:career@soaron.co" className="text-light"> <FontAwesomeIcon icon={faEnvelope} /> career@soaron.co</a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
