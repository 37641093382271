import React, { useEffect, useState } from 'react'
import contents from '../../contents'
import { COLORS, CONTENT_TYPES, PAGES, ROUTES } from '../../constants/constants'

const { TERMS_AND_CONDITIONS_PAGE, PRIVACY_POLICY_PAGE, REFUND_POLICY_PAGE } = contents

export default function ContentPage({ page }) {

    const [data, setData] = useState(null);
    const [title, setTitle] = useState(null);

    useEffect(() => {
        if (page === ROUTES.TERMS_AND_CONDITIONS) {
            setData(TERMS_AND_CONDITIONS_PAGE)
            setTitle(PAGES.TERMS_AND_CONDITIONS.title.toUpperCase())
        }
        else if (page === ROUTES.PRIVACY_POLICY) {
            setData(PRIVACY_POLICY_PAGE)
            setTitle(PAGES.PRIVACY_POLICY.title.toUpperCase())
        }
        else if (page === ROUTES.REFUND_POLICY) {
            setData(REFUND_POLICY_PAGE)
            setTitle(PAGES.REFUND_POLICY.title.toUpperCase())
        }
        else {
            setData(null)
            setTitle(null)
        }
    }, [page])

    return (
        <div style={{ backgroundColor: COLORS.BG }}>
            <div className='container py-5 px-3' style={{ color: COLORS.LIGHT }}>
                <div className="h3 my-3 fw-bold">{title}</div>
                {
                    data && data.map((content, index) => {
                        if (content.type === CONTENT_TYPES.PARA) {
                            return (
                                <div style={{ marginLeft: 20 * content.level, marginBottom: 10 * (3 - content.level) + 20 }}>
                                    <div style={{ marginBottom: 50, fontFamily: 'Quicksand' }} key={index}>
                                        {content.value}
                                    </div>
                                </div>
                            )
                        }
                        else if (content.type === CONTENT_TYPES.HEADING) {
                            return (
                                <div style={{ marginLeft: 20 * content.level, marginBottom: 10 * (3 - content.level) + 15 }}>
                                    <div className="h5" style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>{content.value}</div>
                                </div>
                            )
                        }
                        else if (content.type === CONTENT_TYPES.SUB_HEADING) {
                            return (
                                <div style={{ marginLeft: 20 * content.level, marginBottom: 10 * (3 - content.level) + 10 }}>
                                    <div className="h6" style={{ fontFamily: 'Poppins', fontWeight: 'bold' }}>{content.value}</div>
                                </div>
                            )
                        }
                        else if (content.type === CONTENT_TYPES.TEXT) {
                            return (
                                <div style={{ marginLeft: 20 * content.level, marginBottom: 10 * (3 - content.level) + 10 }}>
                                    <div key={index} style={{ fontFamily: 'Quicksand' }}>
                                        {content.value}
                                    </div>
                                </div>
                            )
                        }
                        else if (content.type === CONTENT_TYPES.LIST) {
                            return (
                                <div style={{ marginLeft: 20 * content.level, marginBottom: 10 * (3 - content.level) + 20 }}>
                                    <div className={content.level > 0 ? "h6" : "h5"}
                                        style={{
                                            fontFamily: 'Poppins',
                                            fontWeight: 'bold',
                                            marginBottom: 20
                                        }}
                                    >
                                        {content.title}
                                    </div>
                                    <div className="h6" style={{ fontFamily: 'Quicksand', fontWeight: 'bold' }}>{content.headline}</div>
                                    <ul className="ul" style={{}}>
                                        {
                                            content.value.map((item, index) => (
                                                <li className='li' key={index} style={{ fontFamily: 'Quicksand', marginBottom: 10, }}>
                                                    {item}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            )
                        }
                        else return <div></div>
                    })
                }
            </div>
        </div>

    )




}
