import emailjs from '@emailjs/browser'
export const sendEmail = async (data) => {
    await emailjs
        .send(
            "service_dgjxo2j",
            "template_732j1kk",
            data,
            "HgRU4nt5-3kPALPaD",
        ).then((res) => {
            console.log(res);
            alert("Thank you for your interest! We will get back to you soon.");
        })
        .catch((err) => {
            console.log(err);
        })
}