export const STORY = {
    SECTION_1: {
        TITLE: "Purpose of Soaron",
        DESCRIPTION: "Soaron was built on the ideology of Innovation and Productivity as the key to open the door of Success. Soaron always aims to make people’s life comfortable and easier by bringing the emerging technologies available to the world.",
        LIST: [
            {
                DATE: 'February 2019',
                YEAR: '2019',
                TITLE: "Soaron was Founded",
                DESCRIPTION: "The Inception of Soaron Aerospace Innovations Private Limited was in February 2019 with the team count of 3, on board with the dream to make Soaron as one of the best drone companies and be the one stop solution to the Aerospace Industry by making ground-breaking innovations."
            },
            {
                DATE: 'January 2020',
                YEAR: '2020',
                TITLE: "Robotic Arm Roller Module",
                DESCRIPTION: "After many trials and R&D, The First Test of RA (Robotic Arm) Dynamic Roller module of colorown in January 2020 was the first achievement of Soaron which brought colors of joy and inspiration to keep going for bigger things."
            },
            {
                DATE: 'December 2021',
                YEAR: '2021',
                TITLE: "Funding and Investments",
                DESCRIPTION: "After Successful testing of RA Roller Module, We hustled for funding and Investments, In the month of December Soaron raised 25 lakhs rupees of Investments on the company. This was the first step towards making Soaron a Profitable Portfolio."
            },
            {
                DATE: 'February 2022',
                YEAR: '2022',
                TITLE: "Soaron Fly",
                DESCRIPTION: "Soaron always Hustle to keep innovation alive, the thought of connecting drone pilots to Users, drove us to the invention of “Soaron fly”. Where people can plump the drones according to their choice and accessibility."
            },
            {
                DATE: 'October 2023',
                YEAR: '2023',
                TITLE: "Spray Module",
                DESCRIPTION: "The Success of RA Roller Module, we started a new project division in Colorown as a “spray module”. We have put lots of research and analytics together and made a prototype and tested in the month of October and successfully crossed the milestone of testing."
            },

        ]
    },
    SECTION_2: {
        TITLE: "Story of Colorown",
        DESCRIPTION: "The initial proposition of Colorown was in the year  2020 as RA (Robotic Arm) Roller Module, later the prototype was made in the month of August, 2020. The testing of the first RA Roller module gave us lots of insights and utilities in design.\nIn the year 2021 the improvisation in the design was done, the test of the new model of UAV RA roller Module was done in January 2021. The success of the test was enormous, this was the stepping stone of Colorown in Soaron.\nAfter the Successful modeling of UAV RA Roller Module, the innovation, R&D were resumed and the idea of a new model in UVA RA as the “UVA RA Spray module” was started in 2022 and the first test of the RA Spray Module was done in October 2023, But The innovation Kept going…!"
    },
    SECTION_3: {
        VIDEO: "https://www.youtube.com/embed/hNGGBuhXUbo?controls=0"
    },
    SECTION_4: {
        TITLE: "Leadership Team",
        CARDS: [
            {
                IMAGE: "assets/shariq.jpg",
                TITLE: "Shariq Ahmed",
                DESCRIPTION: "Founder & CEO",
            },
            {
                IMAGE: "assets/abhay.jpg",
                TITLE: "Abhay Awachar",
                DESCRIPTION: "Co-Founder",
            },
            {
                IMAGE: "assets/srinivas.png",
                TITLE: "Srinivas B",
                DESCRIPTION: "CPO",
            },
            {
                IMAGE: "assets/irfan.png",
                TITLE: "Mir Irfan",
                DESCRIPTION: "COO",
            },
            {
                IMAGE: "assets/manav.jpg",
                TITLE: "Manav Bhatija",
                DESCRIPTION: "CTO",
            },

        ]
    }
}