export const INVEST = {
    SECTION_1: {
        TITLE: "COLOROWN",
        SUBTITLE: "Smart Solutions for Safer, Faster High-Rise Painting",
        DESCRIPTION:
            "Colorown simplifies high-rise painting hassles with smart automation, making it faster, safer, and precise. Its standout feature is cutting-edge tech, reducing project time and costs while being environmentally friendly.",
    },
    SECTION_2: {
        TITLE: "Soaron's Journey",
        SUBTITLE: "Resilience, Camaraderie, and Community Support",
        DESCRIPTION:
            "When Shariq, our determined founder, faced 1500 rejections in the first six months of building Soaron, it was tough. Instead of giving up, he turned every 'no' into motivation to solve real community problems. Today, Soaron is like a family, with people from top institutions and experienced professionals joining us.\nOur journey with Colorown started with just 2-3 interns, thinking it would take six months. It turned out to be a challenging and emotional journey, perfecting our revolutionary product. From the propulsion system to the robotic arm, every step tested our patience and dedication, creating a strong bond within the team.\nRaising funds was another emotional challenge for Shariq. He invested his own money, showing his belief, and with guidance from mentor Bhanu Prakash Varla, he raised pre-seed funds from our close-knit community. Soaron is more than a company; it's a story of resilience, camaraderie, and personal investment turning into community support.",
        COLUMNS: ["JOB TITLE", "LOCATION", "EMPLOYMENT TYPE"],
    },
    SECTION_3: {
        TITLE: "Benifits of Colorown",
        LIST: [
            {
                TITLE: "Automated",
                DESCRIPTION:
                    "Eliminates risks of manual labor.",
            },
            {
                TITLE: 'Swift Completion',
                DESCRIPTION:
                    "Reduces project timeline.",
            },
            {
                TITLE: 'Minimises Expenses',
                DESCRIPTION:
                    "Ensures substantial savings.",
            },
            {
                TITLE: 'Uniform',
                DESCRIPTION:
                    "Guarantees high-quality.",
            },
            {
                TITLE: 'Adaptable Design',
                DESCRIPTION:
                    "Fits diverse structures.",
            },
            {
                TITLE: 'Minimises Wastage',
                DESCRIPTION:
                    "Align with sustainability.",
            },


        ]
    },
    SECTION_4: {
        CARDS: [
            {
                IMAGE: "/assets/TATA.png",
                TITLE: "TATA & Soaron",
                DESCRIPTION:
                    "How Tata will be useful for Soaron for Colorown?",
                LIST: [
                    "Diverse Expertise",
                    "Scale and Reach",
                    "Investment and Funding",
                    "Technological Advancements",
                    "Industry Networking"
                ]
            },
            {
                IMAGE: "/assets/AP.png",
                TITLE: "Asian Paints & Soaron",
                DESCRIPTION:
                    "If we collaborate with Asian Paints then how it benefits us with respect to Colorown?",
                LIST: [
                    "Market Expansion",
                    "Expertise and Resources",
                    "Brand Credibility",
                    "Research and Development",
                    "Cost-efficiency",
                    "Marketing and Promotion",
                ]
            },
        ]
    },
    SECTION_5: {
        TITLE: "Investment Opportunity Highlights",
        ROW_1: [
            {
                IMAGE: "/assets/PV.png",
                TITLE: "Pidilite Ventures",
                DESCRIPTION:
                    "Approached for Next Round",
            },
            {
                IMAGE: "/assets/AV.png",
                TITLE: "Anfa VC",
                DESCRIPTION:
                    "Approached for Next Round",
            },
        ],
        ROW_2: [
            {
                IMAGE: "/assets/growth.png",
                TITLE: "Success Track",
                DESCRIPTION:
                    "Secured ₹50 Lakhs from Friends & Family, showcasing early confidence.",
            },
            {
                IMAGE: "/assets/funds.png",
                TITLE: "Funding Round",
                DESCRIPTION:
                    "Seeking ₹25 - ₹50 Lakhs for the next growth phase.",
            },
            {
                IMAGE: "/assets/percentage.png",
                TITLE: "Equity Offered",
                DESCRIPTION:
                    "0.5 - 1% for a fair partnership.",
            },
        ],
    },
    SECTION_6: {
        TITLE: "Fund Breakdown",
        DESCRIPTION: "Soaron is committed to delivering Colorown to early adopters, with a deadline set for mid-January, particularly for TATA. Your investment will not only make Colorown accessible to those who genuinely need it but also contribute to Soaron's success as a profitable company.",
        CARDS: [
            {
                TITLE: "Product Development",
                PERCENTAGE: "50%",
                DESCRIPTION: "Innoavte and stay competitive",
            },
            {
                TITLE: "Team Salaries",
                PERCENTAGE: "25%",
                DESCRIPTION: "Retain and motivate a skilled workforce.",
            },
            {
                TITLE: "Market & Sales",
                PERCENTAGE: "15%",
                DESCRIPTION: "Boost brand visibility and adoption.",
            },
            {
                TITLE: "Miscellaneous",
                PERCENTAGE: "10%",
                DESCRIPTION: "Flexibility for unforeseen challenges.",
            },
        ],
        BUTTONTEXT: "Invest@Soaron",
    }

};