export const PARTNER_WITH_US = {
    SECTION_1: {
        TITLE: "Partner With Us",
        DESCRIPTION: "We are always looking for new partners to join our network. By partnering with us, you will gain access to a wide range of resources and opportunities. Our partners benefit from our extensive industry knowledge, our innovative solutions, and our commitment to their success.\nWhether you are a small startup or a large corporation, we believe in the power of partnership. We work closely with our partners to understand their needs and provide tailored solutions that drive growth and success.",
        IMAGE: 'assets/partnership.jpg'
    },
    SECTION_2: {
        FORM: {
            TITLE: "Interested in partnering with us?",
            DESCRIPTION: "Please fill out the form below and we will get back to you as soon as possible.",
            INPUTS: [
                {
                    LABEL: "Name",
                    PLACEHOLDER: "Enter your name",
                    TYPE: "text",
                    REQUIRED: true,
                    COL: 6
                },
                {
                    LABEL: "Email",
                    PLACEHOLDER: "Enter your email",
                    TYPE: "email",
                    REQUIRED: true,
                    COL: 6
                },
                {
                    LABEL: "Phone",
                    PLACEHOLDER: "Enter your phone number",
                    TYPE: "tel",
                    REQUIRED: true,
                    COL: 6
                },
                {
                    LABEL: "Website",
                    PLACEHOLDER: "Enter your website",
                    TYPE: "text",
                    REQUIRED: true,
                    COL: 6
                },
                {
                    LABEL: "Company",
                    PLACEHOLDER: "Enter your company name",
                    TYPE: "text",
                    REQUIRED: true,
                    COL: 6
                },
                {
                    LABEL: 'Address',
                    PLACEHOLDER: 'Enter your company address',
                    TYPE: 'text',
                    REQUIRED: true,
                    COL: 6
                },
                {
                    LABEL: "Message",
                    PLACEHOLDER: "Enter your message",
                    TYPE: "textarea",
                    REQUIRED: false,
                    COL: 12
                },
            ]
        },
    }
}