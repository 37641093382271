import { CAREER } from "./career";
import { HOME } from "./home";
import { INVEST } from "./invest";
import { PARTNER_WITH_US } from "./partnerWithUs";
import { PATENT_AND_TRADEMARK } from "./patentAndTrademark";
import { PRESS_OR_MEDIA } from "./pressOrMedia";
import { PRIVACY_POLICY } from "./privacyPolicy";
import { REFUND_POLICY } from "./refundPolicy";
import { STORY } from "./story";
import { TERMS_AND_CONDITIONS } from "./termsAndConditions";

/* eslint-disable import/no-anonymous-default-export */
export default {
    LANDING_PAGE: HOME,
    CAREER_PAGE: CAREER,
    TERMS_AND_CONDITIONS_PAGE: TERMS_AND_CONDITIONS,
    PRIVACY_POLICY_PAGE: PRIVACY_POLICY,
    REFUND_POLICY_PAGE: REFUND_POLICY,
    INVEST_PAGE: INVEST,
    STORY_PAGE: STORY,
    PATENT_AND_TRADEMARK_PAGE: PATENT_AND_TRADEMARK,
    PRESS_OR_MEDIA_PAGE: PRESS_OR_MEDIA,
    PARTNER_WITH_US_PAGE: PARTNER_WITH_US
};
