const jobsData = [
  {
    id: 1,
    title: "Robotics Engineer",
    location: "Hyderabad",
    date: "November 10, 2023",
    type: "Contractual",
    tagline: "Life is Short, Work Somewhere Awesome!",
    description: "Autonomous Painting Robotic Arm Integration",
    jobDetails: [
      {
        title: "Responsibilities",
        description: [
          "Robotic Arm Development: Design and build a robotic arm capable of performing painting tasks using both roller and spray techniques. This includes developing the arm's kinematics, dynamics, and end-effector tools.",
          "Drone Integration: Collaborate with the drone team to integrate the robotic arm with the drone platform, ensuring stability, control, and safety during flight.",
          "Autonomous Control: Develop control algorithms to enable autonomous operation of the entire system, allowing it to perform painting tasks with high precision.",
          "Sensors and Perception: Implement sensor systems and computer vision solutions to enable the robot to perceive and adapt to its environment, recognizing painting targets and adjusting its actions accordingly.",
          "Safety Protocols: Establish safety protocols and fail-safe mechanisms to prevent accidents and ensure safe operation in various environments.",
          "Testing and Optimization: Conduct extensive testing, fine-tuning, and optimization of the robotic arm, drone, and control systems to achieve optimal performance and reliability.",
          "Documentation: Maintain detailed documentation of design, development, and testing processes to ensure transparency and knowledge transfer within the team.",
          "Collaboration: Work closely with cross-functional teams, including hardware engineers, software developers, and drone pilots, to achieve project goals."
        ],
      },
      // {
      //   title: "Skills",
      //   description: [
      //     "Knowledge of Sensors for measuring displacement, rotation, speed etc.",
      //     "Strong Knowledge in Robotics.",
      //     "Knowledge of Kinematics (DOF, joints, velocity and acceleration analysis).",
      //     "Knowledge of coding in C, MATLAB etc.",
      //     "Knowledge of Forward and Inverse kinematics for motion planning.",
      //     "Experience of hardware design/ PCB design from grounds up would be a plus.",
      //     "Hands-on Experience with robotic arm and sensors.",
      //     "Team player, learner, responsible, and good communication skills.",
      //   ],
      // },
      {
        title: "Qualifications",
        description: [
          "Bachelor's degree in Robotics, Mechanical Engineering, or a related field (Master's or Ph.D. preferred).",
          "Proven experience in developing and implementing robotic systems, path planning algorithms and especially robotic arms.",
          "Strong proficiency in programming languages (e.g., C++, Python) for control and automation.",
          "Experience with ROS (Robot Operating System) and simulation tools.",
          "Familiarity with computer vision and machine learning for perception and decision-making.",
          "Knowledge of drone technology and control systems is a plus.",
          "Exceptional problem-solving skills and a passion for innovation.",
          "Strong communication and teamwork abilities."
        ],
      },
      // {
      //   title: "Benefits",
      //   description: [
      //     "Work from anywhere and anytime.",
      //     "You can work part-time, if you are associated with other organisations fulltime.",
      //     "Work with true innovators.",
      //   ],
      // },
    ],
  },
  {
    id: 2,
    title: "Embedded and Controls Engineer",
    location: "Hyderabad",
    date: "November 10, 2023",
    type: "Contractual",
    tagline: "Life is Short, Work Somewhere Awesome!",
    description: "We are hiring Embedded and Controls Engineer",
    jobDetails: [
      {
        title: "Responsibilities",
        description: [
          "Embedded System Design: Lead the development of the entire embedded system for the autonomous painting drone, including selecting appropriate hardware components and configuring the system architecture.",
          "Modeling and Simulation: Utilize Matlab and Simulink to model the plant dynamics of the drone, robotic arm, pumping system and other systems enabling accurate prediction of behavior and performance.",
          "Control System Design: Develop control algorithms for the drone, ensuring precise and stable performance during roller and spray painting operations.",
          "Sensor Integration: Collaborate with the hardware team to integrate sensors, cameras, and other peripherals to provide real-time data for decision-making and navigation.",
          "Software Development: Create and optimize software for the embedded system, implementing control algorithms and ensuring the system operates autonomously and efficiently.",
          "Testing and Validation: Conduct extensive testing, simulations, and real-world trials to validate the performance of the embedded system, making necessary adjustments and refinements.",
          "Safety and Compliance: Ensure the embedded system complies with safety regulations and design software and control systems that include fail-safe mechanisms.",
          "Documentation: Maintain detailed documentation of system design, control algorithms, and software for knowledge sharing and future reference.",
          "Collaboration: Work closely with cross-functional teams, including mechanical and robotics engineers, to ensure seamless integration of the embedded system with the drone's mechanical components."
        ],
      },
      // {
      //   title: "Skills",
      //   description: [
      //     "Knowledge of Sensors for measuring displacement, rotation, speed etc.",
      //     "Strong Knowledge in Robotics.",
      //     "Knowledge of Kinematics (DOF, joints, velocity and acceleration analysis).",
      //     "Knowledge of coding in C, MATLAB etc.",
      //     "Knowledge of Forward and Inverse kinematics for motion planning.",
      //     "Experience of hardware design/ PCB design from grounds up would be a plus.",
      //     "Hands-on Experience with robotic arm and sensors.",
      //     "Team player, learner, responsible, and good communication skills.",
      //   ],
      // },
      {
        title: "Qualifications",
        description: [
          "Bachelor's or Master's degree in Electrical Engineering, Computer Science, or a related field.",
          "Proven experience in embedded systems design and control systems development.",
          "Strong proficiency in Matlab and Simulink for modeling and simulation.",
          "Familiarity with hardware components, microcontrollers, and real-time operating systems.",
          "Programming skills in C/C++ and experience with embedded software development.",
          "Knowledge of sensor integration, image processing, and computer vision.",
          "Exceptional problem-solving skills and analytical skills.",
          "Strong communication and teamwork abilities."
        ],
      },
      // {
      //   title: "Benefits",
      //   description: [
      //     "Work from anywhere and anytime.",
      //     "You can work part-time, if you are associated with other organisations fulltime.",
      //     "Work with true innovators.",
      //   ],
      // },
    ],
  },
  // {
  //   id: 2,
  //   title: "UAV Mechanical Engineer",
  //   location: "Hybrid/Hydrabad",
  //   date: "October 7, 2021",
  //   type: "Part-Time",
  //   tagline: "Life is Short, Work Somewhere Awesome!",
  //   description: "We are hiring skilled UAV Mechanical Engineer.",
  //   jobDetails: [
  //     {
  //       title: "Responsibilities",
  //       description: [
  //         "Research and Development of the product.",
  //         "GD & T analysis and tolerance stackup analysis for the assembly.",
  //         "Calibrating the drone.",
  //         "Testing and Developing the drone.",
  //         "Collaboration with team for design improvement and cost optimization",
  //         "Working with vendors for manufacturing the parts",
  //         "Product verification and validation.",
  //       ],
  //     },
  //     {
  //       title: "Skills",
  //       description: [
  //         "Knowledge of flight mechanics and dynamics.",
  //         "Knowledge of simulation tools.",
  //         "Knowledge of design for assembly and manufacturing.",
  //         "Knowledge of strength of materials.",
  //         "CAD modelling.",
  //         "Hands-on Experience with drones.",
  //         "Team player, learner, responsible and good communication skills.",
  //         "Development of Prototypes.",
  //         "Experience in flying drone.",
  //       ],
  //     },
  //     {
  //       title: "Requirements",
  //       description: [
  //         "Minimum of 5 years experience in relevant field and in new product development",

  //         "Experience in product verification and validation processes.",
  //         "Expert in Failure mode effect and criticality analysis for the design.",
  //         "Experience in user needs and performance requirements for the product.",
  //         "B.E / B.Tech or M.Tech in Mechanical, Aerospace or any other relevant degree.",
  //       ],
  //     },
  //     {
  //       title: "Benefits",
  //       description: [
  //         "Work from anywhere.",
  //         "You can work part-time, if you are associated with other organisation fulltime.",
  //         "Work with true innovators.",
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   title: "UAV Electrical Engineer",
  //   location: "Hybrid/Hydrabad",
  //   date: "October 7, 2021",
  //   type: "Part-Time",
  //   tagline: "Life is Short, Work Somewhere Awesome!",
  //   description: "We are hiring for UAV Electrical Engineer.",
  //   jobDetails: [
  //     {
  //       title: "Responsibilities",
  //       description: [
  //         "You will be working with designer to lay the cable runs into the CAD model of the airframe",
  //         "Embedded systems and microcontroller implementation and programming",
  //         "UAV / Aircraft Integration and Testing",
  //         "Troubleshoot and perform repairs and upgrades",
  //         "Code development on microcontrollers and systems including data capture, manipulation and transfer from embedded platforms including Beaglebone, Rasberry Pi, MSP430, Arduino, IMX 6 / 7 / 8, TX2 as well as LINUX on embedded platforms",
  //         "Development of code in C / C++ / Python on embedded platforms",
  //       ],
  //     },
  //     {
  //       title: "Skills",
  //       description: [
  //         "Knowledge of Control systems.",
  //         "Knowledge of electrical circuits and components.",
  //         "Knowledge of signals and systems.",
  //         "Familiarity with electronics, batteries, motors, and sensors used in UAVs.",
  //         "Experience working with Robotic Systems and Radio Controlled, Unmanned, or Full-Scale aircraft.",
  //         "Team player, learner, responsible and good communication skills.",
  //         "Development of Prototypes.",
  //       ],
  //     },
  //     {
  //       title: "Requirements",
  //       description: [
  //         "Bachelor's degree in engineering, Electronics engineering, or relevant field.",
  //         "3 + years  work experience in hardware design & development, schematic design, board design, digital and analog electronics.",
  //         "Experience in PCB designing by using the software like KiCAD / EagleCAD.",
  //         "In-depth knowledge of electrical engineering.",
  //       ],
  //     },
  //     {
  //       title: "Benefits",
  //       description: [
  //         "Work from anywhere and anytime.",
  //         "You can work part-time, if you are associated with other organisations fulltime.",
  //         "Work with true innovators.",
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   title: "Computer Vision Engineer (AI/ML)",
  //   location: "Hybrid/Hydrabad",
  //   date: "October 7, 2021",
  //   type: "Part-Time",
  //   tagline: "Life is Short, Work Somewhere Awesome!",
  //   description: "We are hiring Robotic Engineer.",
  //   jobDetails: [
  //     // {
  //     //    title: 'Responsibilities',
  //     //    description: [
  //     //       'Meeting with business, marketing, and the R&D team to discuss product ideas.',
  //     //       'Hardware & PCB designing using software like Fusion 360, EagleCAD etc.',
  //     //       'Testing prototype products for functionality, ease of use, and longevity.',
  //     //       'Develop and execute automated manufacturing cells.',
  //     //       'Resolve automation related issues.'
  //     //    ]
  //     // },
  //     {
  //       title: "Skills",
  //       description: [
  //         "Fluency in C / C++, Python, OpenCV, OpenGL, MATLAB.",
  //         "Experience in Image Segmentation, Object Tracking, Object Analysis, Motion Detection etc.",
  //         "Experience in machine learning algorithms for vision problems, including deep learning.",
  //         "Experience in robotics is desirable.",
  //         "Knowledge of internal hardware, 3D camera designs, design logic, and camera logic",
  //         "Knowledge in Git.",
  //       ],
  //     },
  //     // {
  //     //    title: 'Requirements',
  //     //    description: [
  //     //       'Bachelor\'s degree in Robotics engineering, mechatronics or relevant field.',
  //     //       '2+ years work experience in robotics.',
  //     //       'Excellent communication skills.',
  //     //       'Experience with project management will add advantage.'
  //     //    ]
  //     // },
  //     {
  //       title: "Benefits",
  //       description: [
  //         "Work from anywhere and anytime.",
  //         "You can work part-time, if you are associated with other organisations fulltime.",
  //         "Work with true innovators.",
  //       ],
  //     },
  //   ],
  // },
  // {
  //   id: 5,
  //   title: "3D Printing Specialist",
  //   location: "Hybrid/Hydrabad",
  //   date: "October 7, 2021",
  //   type: "Part-Time",
  //   tagline: "Life is Short, Work Somewhere Awesome!",
  //   description: "We are hiring 3D Printing Specialist.",
  //   jobDetails: [
  //     // {
  //     //    title: 'Responsibilities',
  //     //    description: [
  //     //       'Meeting with business, marketing, and the R&D team to discuss product ideas.',
  //     //       'Hardware & PCB designing using software like Fusion 360, EagleCAD etc.',
  //     //       'Testing prototype products for functionality, ease of use, and longevity.',
  //     //       'Develop and execute automated manufacturing cells.',
  //     //       'Resolve automation related issues.'
  //     //    ]
  //     // },
  //     {
  //       title: "Skills",
  //       description: [
  //         "Experience in industrial design",
  //         "Knowledge in Drones(Recommended)",
  //         "Hands-on experience with computer 'aided design(CAD) tools like Fusion 360.",
  //         "Knowledge in 2D Drawing.",
  //         "Good knowledge of 3D modelling.",
  //         "Should have the Vision for the Product",
  //         "Creativity in mixing up colours, shapes and techniques",
  //       ],
  //     },
  //     // {
  //     //    title: 'Requirements',
  //     //    description: [
  //     //       'Bachelor\'s degree in Robotics engineering, mechatronics or relevant field.',
  //     //       '2+ years work experience in robotics.',
  //     //       'Excellent communication skills.',
  //     //       'Experience with project management will add advantage.'
  //     //    ]
  //     // },
  //     {
  //       title: "Benefits",
  //       description: [
  //         "Work from anywhere and anytime.",
  //         "You can work part-time, if you are associated with other organisations fulltime.",
  //         "Work with true innovators.",
  //       ],
  //     },
  //   ],
  // },
];

export default jobsData;
