import React from 'react'
import './style.css'

export default function CommingSoon() {
    return (
        <div className="cs-container">
            <div className="cs-wrapper">
                <div className="cs-content">
                    <div className="cs-item">
                        <div className='cs-heading'>COMING SOON</div>
                        <div className='cs-para'>This webpage is under construction.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
