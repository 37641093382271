export const PRESS_OR_MEDIA = {
    SECTION_1: {
        DATE: "May 17, 2021",
        TITLE: "Painting highrise buildings are a risk for a painter.",
        DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Sed vitae .",
    },
    SECTION_2: {
        TITLE: "Press Release",
        CARDS: [
            {
                IMAGE: "https://picsum.photos/300/200",
                TITLE: "Painting highrise buildings are a risk for a painter.",
                DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Sed vitae .",
                DATE: "May 17, 2021",
                LINK: "/press-or-media",
            },
            {
                IMAGE: "https://picsum.photos/300/200",
                TITLE: "Painting highrise buildings are a risk for a painter.",
                DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Sed vitae .",
                DATE: "May 17, 2021",
                LINK: "/press-or-media",
            },
            {
                IMAGE: "https://picsum.photos/300/200",
                TITLE: "Painting highrise buildings are a risk for a painter.",
                DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Sed vitae .",
                DATE: "May 17, 2021",
                LINK: "/press-or-media",
            },
            {
                IMAGE: "https://picsum.photos/300/200",
                TITLE: "Painting highrise buildings are a risk for a painter.",
                DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Sed vitae .",
                DATE: "May 17, 2021",
                LINK: "/press-or-media",
            },
            {
                IMAGE: "https://picsum.photos/300/200",
                TITLE: "Painting highrise buildings are a risk for a painter.",
                DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Sed vitae .",
                DATE: "May 17, 2021",
                LINK: "/press-or-media",
            },
            {
                IMAGE: "https://picsum.photos/300/200",
                TITLE: "Painting highrise buildings are a risk for a painter.",
                DESCRIPTION: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam diam sit amet elit hendrerit rutrum. Nam egestas laoreet ligula, ac elementum risus. Aliquam volutpat ex eget elit venenatis, vel luctus arcu pulvinar. Sed vitae .",
                DATE: "May 17, 2021",
                LINK: "/press-or-media",
            },

        ]
    }
}