import { CONTENT_TYPES } from "../constants/constants";

export const TERMS_AND_CONDITIONS = [
    {
        type: CONTENT_TYPES.PARA,
        level: 0,
        value: 'THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF INFORMATION TECHNOLOGY ACT, 2000 AND RULES THEREUNDER AS APPLICABLE AND THE PROVISIONS PERTAINING TO ELECTRONIC RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. Soaron Aerospace innovations Private Limited (“Company”) provides technology-based services for booking drone service (“FLY”) to you (“You” or “Users”) and you agree to obtain certain Services (defined hereinafter) offered by third party Drone operators ("Flytors") by means of the Company’s website and the mobile application “FLY” (“Platform”). All the Services provided by the Company to you would be by means of your use of the Platform. These Terms of Use shall govern the relationship between you (the customer) and the Company in the course of provision of the Services. These terms of use (“Terms of Use”) mandate the terms on which users using the Services will be governed. Please read the Terms of Use carefully before using the Platform or registering on the Platform or accessing any material or information through the Platform. Use of and access to the Platform is offered to You upon the condition of acceptance of all the terms, conditions and notices contained in this Terms of Use and Privacy Policy, along with any amendments made by the Company at its sole discretion and posted on the Platform from time to time.'
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'SERVICES',
        headline: 'The Platform provides the following services (“Services”) to You:',
        value: [
            "It allows you to book drone services provided by the third party (Flytor) in the below categories i.e., Videography, agriculture, survelliance& mapping through our mobile app (FLY)."]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'GENERAL TERMS OF USE',
        headline: "",
        value: [
            'You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if different than 18 years), to obtain an account.',
            'The Service is not available for use by persons under the age of 18 years. You shall not authorize third parties to use your account.',
            'If the Company becomes aware or acquires credible knowledge that You have misled us regarding your age, then the Company reserves its rights to deactivate the account, and You will not be liable to raise any claims, including any insurance from the Company.',
            'The Company shall take your booking request and forward it to the operator (FLYTOR) through an app-based device operating on GPS-GPRS based device available with the operator (Flytor).',
            'The Company may monitor and record calls made to the Flytors for the purpose of training and improving customer care services, including complaint.',
            'The operator (Flytor) shall have the sole discretion to accept or reject each request for the Service.',
            'If the operator (Flytor) accepts the booking request made by the Company, a notification will be sent to you with the information regarding the operator (Flytor), including its name, contact number, etc.',
            'The Company shall make reasonable efforts to bring you in contact with the operator (Flytor) to obtain the Service subject to the availability of the operator (Flytor) in or around your location at the time of your booking request made to the Company.',
            'For the avoidance of doubt, it is clarified that the Company itself does not provide the Services. It is the operator (Flytor) who shall render the Services to you.',
            'Even after acceptance of booking, the Operator (Flytor) may not reach your location or decide not to render his services, in which event the Company shall not be held liable.',
            'You warrant that the information you provide to the Company is accurate and complete. The Company is entitled at all times to verify the information that you have provided. You may only access the Services using authorized means.',
            'The Company shall not be liable if you do not download the correct Platform or visit the appropriate web portal.',
            'The Company reserves the right to discontinue or introduce any of the modes of booking drones.',
            'You will refrain from doing anything which we reasonably believe to be disreputable or capable of damaging our reputation and will comply with all applicable laws of the Republic of India.',
            'You will treat the Operator (Flytor) with respect and not cause damage to their drone or engage in any unlawful, threatening, harassing, abusive behavior or activity while using their Vehicle.',
            'The Company is not responsible for the behavior, actions, or inactions of Operator (Flytor), or the quality of the drone which you may use. Any contract for the provision of drones for the Services is exclusively between you and the Operator (Flytor), and the Company is not a party to the same.',
            'You agree that You shall not request for Services that are illegal, hazardous, dangerous, or otherwise restricted or constitute Items that are prohibited by any statute or law or regulation or the provisions of this Terms of Use.',
            'You also agree that you shall not request services in no-fly zone areas which require a special permit or require any special license under applicable law.',
            'The Company shall have the right to report You to the government authorities and take other appropriate legal actions against You if you are forcing the operator (Flytor) to deliver services in the restricted areas as defined by the government.',
            'You also agree that, upon becoming aware of the commission of any offense by You or Your intention to commit any offense upon initiating a Service or during Services restricted under applicable law, the Operator (Flytor) may report such information to Company or to the law enforcement authorities.',
            'You also agree that any payment for the services booked by you in respect of the drone Services shall be at your own risk, and the payment shall be settled before initiating the service. Soaron (FLY) does not assume any responsibility or liability whatsoever for any damage/deficiency or loss of the products/goods. The Services are provided to You directly by the Operator (Flytor), and SoaronFLY merely acts as a technology platform to facilitate services initiated on the Platform, and SoaronFLY does not assume any responsibility or liability for any form of deficiency of services on the part of the Operator (Flytor).',
            'You will be solely responsible for any loss or damage to the goods, in case of any theft or any other incidents during the service provided by the Operator (Flytor).',
            'By using the Platform of the Company, you further agree that:',
            'You will download the Application for your sole, personal use and will not resell it to a third party;',
            'You will not authorize others to use your account;',
            'You will not assign or otherwise transfer your account to any other person or legal entity;',
            'You will not use the Application for unlawful purposes, including but not limited to sending or storing any unlawful material or for fraudulent purposes;',
            'You will not use the Application to cause nuisance, annoyance or inconvenience;',
            'You will not impair the proper operation of the network;',
            'You will not try to harm the Application in any way whatsoever;',
            'You will not copy or distribute the Application or other Company Content without written permission from the Company;',
            'You will keep secure and confidential your account password or any identification which the Company may provide you which allows access to the Application;',
            'You will provide the Company with whatever proof of identity we may request;',
            'In order for us to facilitate UPI payments, we are required to conduct a bank account validation and Virtual Payment Address (“VPA”) validation. We conduct these validations through a third-party service provider.',
            'You will only use an access point or at least a 3G data account (AP) which you are authorized to use;',
            'You will not use the Application with an incompatible or unauthorized device;',
            'If within 24 hours a user creates more than one account on the same device, SoaronFLY has the right to terminate his/her account;',
            'Services are currently available in Hyderabad only.',
            'The Company reserves the right to immediately terminate your use of the Application should you not comply with any of the rules provided in the Terms of Use.',
            'You will ensure that you will not book the services in “no-fly” zones (where drones are not permitted to fly) as designated by the DGCA & Local govt authorities. Even If bookings are made & accepted by the flytor in the no-fly zone areas, Soaron will not be responsible for any such bookings or will not be held responsible in any legal aspects.',
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'PAYMENT OF SERVICES',
        headline: "",
        value: [
            'You shall be required to pay charges for the Services used by you either by using the online payment gateway provided in the Platform. The Company collects the charges for the Services on behalf of the Flytors after obtaining authorization from the Flytors and the payment is remitted to the Operator(Flytor) bank account registered with the Company.',
            'You agree and acknowledge that FLY is not in any way be responsible for the settlement between you and the Flytor.',
            'The rates of the Services shall be notified on the website and mobile application of the Company.',
            'The charges for the Services shall be updated or amended from time to time at the sole discretion of the Company and it shall be your responsibility to remain informed about the charges for the Services.',
            'You agree that you will pay for all Services you purchase from the Operator(Flytor) either by way of online payment. In the event the payment cannot be accepted through the online payment or any other mode, you shall be required to pay the charges for the Services availed by way of cash.',
            'Any payment made is non-refundable. At the end of the Services, we will facilitate for you to receive a copy of the invoice from the Company on your registered e-mail account with the Company.',
            'You shall pay the service fees for availing the Services and/or the Delivery Services at the end of the completion of such services, as may be displayed to You on the Platform. You cannot initiate another Services and/or the Delivery Services until You have paid for the previously completed such Services and/or the Delivery Services.'
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'Cancellation & Refund Cancellation Charges',
        headline: '',
        value: [
            'Customers can cancel their booking without any charges if the operator (FLYTOR) has not initiated the service or started his/her journey to your location.',
            'If the operator (FLYTOR) has initiated the journey to your location, a cancellation charge of Rs.100 or 7% of the booking amount, whichever is higher, will be applied.'
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'Cancellation Restrictions',
        headline: '',
        value: [
            'Cancellation of the service cannot be done once the operator (FLYTOR) has reached your location or initiated the service.'
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'Refund Policy',
        headline: '',
        value: [
            "If the booking is canceled by the customer within 12 hours of placing the order, SOARON Aerospace Innovations Pvt Ltd will initiate an instant refund. The refund amount will be the booking amount minus a service charge of 7%.",
            "The refund will be credited to the customer's source account or chosen method of refund within 7-14 working days.",
            "If the booking is canceled by the Flytor (operator), SOARON Aerospace Innovations Pvt Ltd will initiate a full refund instantly.",
            "The refund will be credited to the customer's source account or chosen method of refund within 7-14 working days, provided that the amount has already been paid by the customer."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'LIABILITY',
        headline: '',
        value: [
            "The information, recommendations provided to you on or through the website, or the Platform is for general information purposes only and does not constitute advice.",
            "The Company will reasonably keep the website and the application and its contents correct and up to date but does not guarantee that the website and/or application are free of errors, defects, malware and viruses or that the website and/or application are correct, up to date and accurate. The Company shall not be liable for any damage arising from the same.",
            "The Company shall further not be liable for damages resulting from the use of or the inability to use the website or the application, including – but not limited to – damages resulting from failure or delay in delivery of electronic communications, interception or manipulation of electronic communications by third parties or by computer programs used for electronic communications and transmission of viruses.",
            "The quality of the Services requested through the use of the Platform is entirely the responsibility of the Operator(Flytor) who ultimately provides such Services to you, and the Company is not liable for the same. However, any complaints about the Services provided by the Operator(Flytor) should be submitted to the Company by an email as notified from time to time.",
            "The Company shall not in any way be responsible for any claims raised by You in respect of the products/goods ordered by you in respect of the Delivery Services. It shall be at your own risk, and the payment shall be settled before initiating the service."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'INTELLECTUAL PROPERTY RIGHTS',
        headline: '',
        value: [
            "The Company is the sole owner and lawful licensee of all the rights to the web site, Platform or any other digital media and its contents. The content means its design, layout, text, images, graphics, sounds, video, etc. The website, Platform, or any other digital media content embodies trade secrets and intellectual property rights protected under worldwide copyright and other laws. All titles, ownership, and intellectual property rights in the website and its content shall remain with the Company, its affiliates, agents, authorized representatives, or licensors, as the case may be.",
            "All rights not otherwise claimed under this Terms of Use or by the Company are hereby reserved. The information contained in this Platform and/or website is intended, solely to provide general information for the personal use of the reader, who accepts full responsibility for its use.",
            "The Company does not represent or endorse the accuracy or reliability of any information or advertisement contained on, distributed through, or linked, downloaded or accessed from any of the services contained on this website or Platform, or the quality of any products, information or other materials displayed, or obtained by you as a result of any product, information or other materials displayed, or obtained by you as a result of an advertisement or any other information or offer in or in connection with the service.",
            "All related icons and logos are registered trademarks or service marks or word marks of the Company in various jurisdictions and are protected under applicable copyrights, trademarks, and other proprietary rights laws. The unauthorized copying, modification, use or publication of these marks is strictly prohibited.",
            "All the contents on this website and/or Platform are copyright of the Company except the third party content and link to the third party website on our website and/or Platform.",
            "Subject to your compliance with these Terms, the Company grants you a limited non-exclusive, non-transferable license to download and install a copy of the Platform on a single mobile device that you own or control and to run such copy of the Platform solely for your own personal use.",
            "You shall not do the following: Reverse engineer or access the Platform in order to:",
            " - design or build a competitive product or service,",
            " - design or build a product using similar ideas, features, functions, or graphics of the Platform, or copy any ideas, features, functions, or graphics of the Platform, or launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web indexers, bots, viruses, or worms, or any program that may make multiple server requests per second, or unduly burdens or hinders the operation and/or performance of the Platform."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'THIRD-PARTY LINKS',
        headline: '',
        value: [
            "During the use of the website or the application, links to websites that are owned and controlled by third parties may be provided from time to time in order to enter into correspondence with, purchase goods or services from, participate in promotions of third parties. These links take you off the website, the application and are beyond the Company's control.",
            "During the use of the website and the application, you may enter into correspondence with, purchase goods and/or services from, or participate in promotions of third party Flytors, advertisers or sponsors showing their goods and/or services through a link on the website or through the application or Service. These links take you off the website, the application and the Service and are beyond the Company's control. You therefore visit or access these websites entirely at your own risk.",
            "Please note that these other websites may send their own cookies to users, collect data or solicit personal information, and you are therefore advised to check the terms of use or privacy policies on those websites prior to using them."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'TERM AND TERMINATION',
        headline: '',
        value: [
            "The contract between the Company and you is concluded for an indefinite period. You are entitled to terminate the Contract at all times by permanent deletion of the Platform installed on your mobile device, tablet, or any electronic device capable of using the Platform, thus disabling the use by you of the Platform and the Service.",
            "The Company is entitled to terminate the contract at all times and with immediate effect (by disabling your use of the application and the Service) if you: (a) violate or breach any term of these Terms of Use, or (b) in the opinion of the Company, misuse the Platform or the Service.",
            "The Company is not obliged to give notice of the termination of the contract in advance. After termination, the Company will give notice thereof in accordance with these Terms of Use.",
            "Neither party hereto shall be responsible for delays or failures in performance resulting from acts beyond its reasonable control and without its fault or negligence. Such excusable delays or failures may be caused by, among other things, strikes, lock-out, riots, rebellions, accidental explosions, floods, storms, acts of God, and similar occurrences."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'INDEMNITY',
        headline: '',
        value: [
            "You will indemnify and hold the Company harmless, from any and all claims, losses, liabilities, damages, expenses, and costs (including attorneys’ fees and court costs) which result from a breach or alleged breach of these Terms of Use by you."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION',
        headline: '',
        value: [
            "These Terms of Use shall be governed by and interpreted in all respects in accordance with the laws of the Republic of India.",
            "Subject to the provisions made in Clause 9.3, the Parties hereby submit to the exclusive jurisdiction of the courts of Hyderabad, India.",
            "All disputes arising out of or in relation to these Terms of Use shall be settled amicably by the Parties. In the event no amicable settlement is arrived at within a period of fifteen (15) days from the date of first initiation of the dispute by one Party to the other, the Parties shall resolve the dispute by means of arbitration pursuant to the Arbitration and Conciliation Act, 1996.",
            "The arbitration proceedings shall be conducted by an arbitral tribunal comprising of 1 (one) arbitrator mutually appointed by you and the Company.",
            "The arbitration proceedings shall be conducted in the English language only, and the seat for arbitration shall be Bangalore, India.",
            "The award of the arbitral tribunal shall be final and binding."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'ASSIGNMENT',
        headline: '',
        value: [
            "You may not assign your rights under these Terms of Use without the prior written approval of the Company."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'AMENDMENT',
        headline: '',
        value: [
            "These Terms of Use may be amended from time to time and as and when required, at the discretion of the Company."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'SEVERABILITY',
        headline: '',
        value: [
            "If any provision or any part of a provision of these Terms of Use is invalid, unenforceable or prohibited by applicable laws of the Republic of India, such provision or part of the provision shall be severed from these Terms of Use and shall be considered divisible as to such provision or part thereof. It shall be inoperative and shall not be part of the consideration moving between you and the Company hereto. The remainder of these Terms of Use shall be valid and binding and of like effect as though such provision was not included herein."
        ]
    },
    {
        type: CONTENT_TYPES.LIST,
        level: 0,
        title: 'NOTICES',
        headline: '',
        value: [
            "The Company may give notice by means of a general notice on the Application, or by electronic mail to your email address on record in the Company's account information, or by written communication sent by regular mail to your address on record in the Company's account information."
        ]
    },
]