import { Box, Typography } from "@mui/material";
import React from "react";
import styles from "./styles.module.css";

export default function index({ content, color, gap }) {
    const { TITLE, DESCRIPTION } = content;
    return (
        <Box className={gap ? "mb-5" : null}>
            <Typography
                fontFamily={"poppins"}
                fontSize={"26px"}
                fontWeight={600}
                color={color}
                marginBottom={"20px"}
                className={styles.seventhSectionHeading}>
                {TITLE}
            </Typography>
            <Typography
                fontFamily={"quicksand"}
                fontSize={"16px"}
                color={color}
                className={styles.seventhSectionText}>
                {DESCRIPTION}
            </Typography>
        </Box>
    );
}
