export const HOME = {
    SECTION_1: {
        TITLE: "COLOROWN",
        SUBTITLE: "Paint Highrise Structures With",
    },
    SECTION_2: {
        TITLE: "Painting highrise buildings are a risk for a painter.",
    },
    SECTION_3: {
        TITLE: "Coating the sea links & bridges takes months & years.",
    },
    SECTION_4: {
        TITLE: "Colorown is designed in such a way that it solves all of these issues by saving a human life, speeding up high-quality painting, and lowering costs.",
    },
    SECTION_5: {
        TITLE: "Colorown comes with a Foldable Airframe & Robotic Arm which helps the drone pilot to operate it easily and has 2 modules, Roller and Spray.",
    },
    SECTION_6: {
        TITLE: "REMOTE CONTROL",
        DESCRIPTION:
            "The Colorown remote control is carefully designed for the ease of use by adopting a minimalist design to include all the essential features into a compact and ergonomic configuration.",
    },
    SECTION_7: {
        CONTENTS: [
            {
                TITLE: "Display Screen",
                DESCRIPTION:
                    "The display screen helps the operators by providing them with necessary information such as the volume of paint remaining, Battery level, estimated time of completion, estimated cost and many such things.",
            },
            {
                TITLE: "Control Sticks",
                DESCRIPTION:
                    "The right control stick is for pitch (forward and backwards) and roll (left or right). The left control stick is for yaw (rotation in CW or CCW) and throttle.",
            },
            {
                TITLE: "Push Buttons",
                DESCRIPTION:
                    "The push buttons can be configured by the drone operator depending upon the functionalities they desire.",
            },
            {
                TITLE: "Case Cover",
                DESCRIPTION:
                    "The case cover protects the electronics inside and provides a grip for operators. It is designed to be more ergonomic.",
            },
        ],
    },
    SECTION_8: {
        CONTENTS: [
            {
                TITLE: "Trim Buttons",
                DESCRIPTION:
                    "The trim button is used to adjust the yaw, pitch, roll, and throttle on the drone. We need to keep adjusting them until the drone's aerodynamics are perfectly balanced.",
            },
            {
                TITLE: "Push Buttons",
                DESCRIPTION:
                    "The push buttons can be configured by the drone operator depending upon the functionalities they desire.",
            },
            {
                TITLE: "Power & Charging",
                DESCRIPTION:
                    "The controller is powered by a rechargeable Li-ion battery and the remote control comes with a USB type-C charger for fast charging.",
            },
        ],
    },
};
