export const ROUTES = {
    HOME: '/',
    CAREER: '/career',
    CAREER_JOBS: '/career/jobs',
    CAREER_JOBS_ID: '/career/jobs/:id',
    STORY: '/story',
    PARTNER_WITH_US: '/partner-with-us',
    CONTACT_US: '/contact-us',
    PATENT_AND_TRADEMARK: '/patent-and-trademark',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-conditions',
    REFUND_POLICY: '/refund-policy',
    PRESS_OR_MEDIA: '/press-or-media',
    HELP_AND_SUPPORT: '/contact-us',
    NOT_FOUND: '*',
};

export const PAGES = {
    HOME: {
        title: 'Home',
        route: ROUTES.HOME,
    },
    CAREER: {
        title: 'Career',
        route: ROUTES.CAREER,
    },
    STORY: {
        title: 'Story',
        route: ROUTES.STORY,
    },
    PARTNER_WITH_US: {
        title: 'Partner With Us',
        route: ROUTES.PARTNER_WITH_US,
    },
    CONTACT_US: {
        title: 'Contact Us',
        route: ROUTES.CONTACT_US,
    },
    PATENT_AND_TRADEMARK: {
        title: 'Patent & Trademark',
        route: ROUTES.PATENT_AND_TRADEMARK,
    },
    PRIVACY_POLICY: {
        title: 'Privacy Policy',
        route: ROUTES.PRIVACY_POLICY,
    },
    TERMS_AND_CONDITIONS: {
        title: 'Terms & Conditions',
        route: ROUTES.TERMS_AND_CONDITIONS,
    },
    REFUND_POLICY: {
        title: 'Refund Policy',
        route: ROUTES.REFUND_POLICY,
    },
    PRESS_OR_MEDIA: {
        title: 'Press or Media',
        route: ROUTES.PRESS_OR_MEDIA,
    },
    HELP_AND_SUPPORT: {
        title: 'Help & Support',
        route: ROUTES.HELP_AND_SUPPORT,
    },
};

export const CONTENT_TYPES = {
    PARA: 'para',
    IMAGE: 'image',
    VIDEO: 'video',
    LIST: 'list',
    TABLE: 'table',
    HEADING: 'heading',
    SUB_HEADING: 'sub-heading',
    TEXT: 'text',
};

export const COLORS = {
    LIGHT: '#F8F8F8',
    DARK: '#000000',
    DARK2: '#232b32',
    PRIMARY: '#1D1D1D',
    SECONDARY: '#6F6F6F',
    TERTIARY: '#F8F8F8',
    BG: '#0f151a',
};
