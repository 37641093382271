import React from 'react';

export default function PnTCard({ image, logo, number, status, numberText, textLogo }) {
    return (
        <div
            style={{
                width: '300px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                margin: 'auto',
            }}
        >
            <img
                src={image}
                alt=''
                style={{ width: '70%' }}
            />
            <br />
            {logo && (
                <img
                    src={logo}
                    alt=''
                    style={{ width: '100%' }}
                />
            )}
            <div className='h6 mt-3'> {numberText}</div>
            <div className='h4 fw-bold'>{number}</div>
            <div
                style={{
                    fontFamily: 'Quicksand',
                    fontSize: '14px',
                    marginTop: '10px',
                    backgroundColor: 'black',
                    color: 'white',
                    padding: 10,
                }}
            >
                {status}
            </div>
        </div>
    );
}
