import React from "react";
import contents from "../../contents";

const { STORY_PAGE: { SECTION_1 } } = contents;

export default function StoryPage() {

  const [tab, setTab] = React.useState(0);

  const handleClickTab = (index) => {
    console.log('click');
    setTab(index);
  }

  return (
    <>
      <div
        style={{ background: "url(/assets/story.jpg) no-repeat", backgroundSize: 'cover', color: "white" }}
        className="sectionContainer align-items-md-center pt-md-5"
      >
        <div className="main-box d-flex flex-column text-center">
          <div
            className="fw-bold poppins-bold display-3" style={{ marginBottom: '2rem' }}
          >
            {SECTION_1.TITLE}
          </div>

          <p
            style={{
              fontFamily: "Quicksand",
              fontSize: "20px",
              marginBottom: "25px",
              maxWidth: 1000,
              margin: 'auto',
            }}
          >
            {SECTION_1.DESCRIPTION}
          </p>
        </div>
      </div>
      <div
        className={'sectionContainer align-items-start'}
        style={{
          color: "white", position: 'relative',
        }}
      >
        <div style={{
          position: 'absolute', top: 0, left: 0,
          width: '100%', height: '100%',
          filter: 'blur(3px)',
          backgroundSize: 'cover',
          background: "url(/assets/story2.jpg) no-repeat",
          backgroundPosition: 'center',
          zIndex: 0,
        }} ></div>
        <div style={{
          position: 'absolute', top: 0, left: 0,
          width: '100%', height: '100%',
          background: 'linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,0.8))',
        }} >
          <div className="main-box justify-content-md-between" style={{ height: '70vh', display: 'flex', flexDirection: 'column' }}>
            <div className="row mx-auto main-box">
              {
                SECTION_1.LIST.map((item, i) => (
                  <div className="col p-0 text-center fw-bold" key={i}>
                    <div className="poppins-bold h6 text-light p-2 py-3 p-md-5"
                      style={{
                        fontSize: 20, cursor: 'pointer', backgroundColor: tab === i ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,0.4)',
                        backdropFilter: 'darken'
                      }}
                      onClick={() => handleClickTab(i)}>
                      {item.YEAR}
                    </div>
                  </div>
                ))
              }
            </div>
            <br />
            <br />
            <br />
            <br />
            <div className="d-flex row flex-column justify-content-center mt-5 mx-auto" >
              <div className="px-4">
                <div className="h5">{SECTION_1.LIST[tab].DATE}</div>
                <h1
                  className="h2 fw-bold"
                >
                  {SECTION_1.LIST[tab].TITLE}
                </h1>
                <p className="mt-4 p pe-4" style={{ maxWidth: 1000 }}>
                  {SECTION_1.LIST[tab].DESCRIPTION}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 2nd section */}
      {/* <div
        className={'sectionContainer'}>
        <div className="main-box row">
          <div className="col-12 col-md-0" style={{ height: 40 }}></div>
          <div className="col-12 col-md-5">
            <img
              src={"assets/story3.png"}
              width={"90%"}
              alt={""}
            />
          </div>
          <div className="col-12 col-md-1" style={{ height: 30 }}></div>
          <div className="col-12 col-md-6 my-3 d-flex flex-column justify-content-center" style={{ textAlign: "left", }}>
            <div
              className='display-3'
              style={{ marginBottom: '1.5rem', fontFamily: 'poppins', fontWeight: 'bold' }}>
              {SECTION_2.TITLE}
            </div>
            <div
              className='p'
              style={{ fontFamily: 'poppins' }}>
              {SECTION_2.DESCRIPTION.split('\n').map((item, i) => <div className="mb-3" key={i}>{item} </div>)}
            </div>
            <br /><br />

          </div>
        </div>
      </div> */}

      {/* 3rd section */}
      {/* <div
        className="sectionContainer"
      >
        <div className="main-box row">
          <iframe
            width="100%"
            height="700"
            src={SECTION_3.VIDEO}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div> */}


      {/* 4th section */}
      {/* <div className={'sectionContainer py-5'}>

        <div className="main-box">
          <div
            className="fw-bold mt-3 poppins-bold display-4 text-center" style={{ marginBottom: '2rem' }}
          >
            {SECTION_4.TITLE}
          </div>

          <div className="mx-auto row px-md-5 justify-content-center" style={{ maxWidth: 1200 }}>
            {
              SECTION_4.CARDS.map((item, i) => (
                <div className={`col-6 col-md-${i > 1 ? '4' : '5'} p-3 d-flex flex-column align-items-center`} key={i}>
                  <img src={item.IMAGE} alt="" style={{ borderRadius: '50%', width: 200, maxWidth: '80%' }} className="mb-3 " />
                  <div className="h6 text-center mb-2 fw-bold">{item.TITLE}</div>
                  <div className="small text-center mb-4">{item.DESCRIPTION}</div>
                </div>
              ))
            }
          </div>
        </div>
      </div> */}

    </>
  );
}
