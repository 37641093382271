export const PATENT_AND_TRADEMARK = {
    SECTION_1: {
        TITLE: "Patent",
        DESCRIPTION: "We have filed a patent application with the number 201941043518 and are currently awaiting its grant. The application is under examination by the patent office, and we are prepared to respond to any inquiries or requests for additional information to ensure the approval process proceeds efficiently.",
        IMAGE: 'assets/patents.webp',
        NUMBER: '201941043518',
        STATUS: 'Waiting for grant',
    },
    SECTION_2: {
        TITLE: "Trademarks",
        DESCRIPTION: "The Soaron brand is officially registered with the trademark number 4744569, ensuring exclusive rights and legal protection for our brand identity and logo. This trademark reinforces our brand's recognition and safeguards against unauthorized use, supporting Soaron's market presence and reputation.",
        IMAGE: 'assets/trademark.png',
        NUMBER: '4744569',
        STATUS: 'Registered',
    }
}