export const CAREER = {
    SECTION_1: {
        TITLE: "Career at Soaron",
        SUBTITLE: "Keeping Innovation Alive.",
        DESCRIPTION:
            "We have built our team, which is in line with our vision and mission, who are willing to sacrifice, learn and perform fast, their passion compels them to work together and they wait patiently for the outcome.",
        CTA: "👋 Wanna join our team? Send your resume to",
        EMAIL: "career@soaron.co",
    },
    SECTION_2: {
        TITLE: "OPEN POSITIONS",
        DESCRIPTION:
            "We are looking for people who are passionate about what they do and are willing to learn and grow with us.",
        COLUMNS: ["JOB TITLE", "LOCATION", "EMPLOYMENT TYPE"],
    },
};