import {
  faEnvelope,
  faLocation,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./style.css";

export default function ContactPage() {
  return (
    <>
      <div className="row m-0 p-0" style={{ height: "90vh", width: "100%" }}>
        <iframe
          style={{
            width: "100%",
            height: "90vh",
            padding: 0,
          }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15228.598429510988!2d78.452926!3d17.4046065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb97137178878b%3A0x411871eeffa91e74!2sSoaron%20Aerospace%20Innovations%20Private%20Limited!5e0!3m2!1sen!2sin!4v1680119590239!5m2!1sen!2sin"
          allowFullscreen=""
          loading="lazy"
          title="location"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>

        <div
          className="position-absolute end-0 col-12 col-md-5 contact-box d-flex flex-column justify-content-center align-items-center gap-5"
          style={{ backgroundColor: "rgba(0, 0, 0, .8)" }}
        >
          <div className="text-start text-light poppins-reg ps-5 cont-text">
            <div>
              {" "}
              <FontAwesomeIcon className="me-2" icon={faPhone} /> Phone:{" "}
            </div>
            <div>040 46038583</div>
          </div>
          <div className="text-start text-light poppins-reg ps-5 cont-text">
            <div>
              {" "}
              <FontAwesomeIcon className="me-2" icon={faEnvelope} /> Email:{" "}
            </div>
            <div>support@soaron.co</div>
          </div>
          <div className="text-start text-light poppins-reg ps-5 cont-text">
            <div>
              {" "}
              <FontAwesomeIcon
                className="me-2"
                icon={faLocation}
              /> Address:{" "}
            </div>
            <div>
              10-1-128/1/2D, Ali Manor Complex, 2nd Floor, Masab Tank,
              Hyderabad, Telangana 500028
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
