import React from 'react';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Instagram from '../svgs/Instagram';
import { Link } from 'react-router-dom';
import { PAGES } from '../../constants/constants';

const pages = [
    PAGES.STORY,
    PAGES.PRIVACY_POLICY,
    PAGES.REFUND_POLICY,
    PAGES.CAREER,
    PAGES.TERMS_AND_CONDITIONS,
    PAGES.PARTNER_WITH_US,
    PAGES.PRESS_OR_MEDIA,
    PAGES.PATENT_AND_TRADEMARK,
    PAGES.HELP_AND_SUPPORT,
];

const FooterMobileComponent = ({ setShow }) => {
    return (
        <div style={{ backgroundColor: '#000000' }}>
            <div className='container d-flex justify-content-between py-5'>
                {/* left side */}
                <div className='row ms-3'>
                    {pages.map(({ title, route }) => (
                        <div className='col-6 ps-3 my-2'>
                            <Typography
                                fontSize={'12px'}
                                component={Link}
                                to={route}
                                style={{ textDecoration: 'none' }}
                                fontFamily={'Poppins'}
                                color={'white'}
                            >
                                {title}
                            </Typography>
                        </div>
                    ))}
                </div>
            </div>

            <div
                className='d-flex justify-content-center'
                style={{ borderBottom: '1px solid gray' }}
            >
                <div className='d-flex gap-4 px-5 mb-4'>
                    <a
                        href={'https://www.youtube.com/channel/UCVYcJj6qHAIOa9zBAnLcnbA/'}
                        target={'_blank'}
                        rel='noreferrer'
                    >
                        <FontAwesomeIcon
                            icon={faYoutube}
                            style={{ color: 'white' }}
                        />
                    </a>
                    <a
                        href={'https://www.linkedin.com/company/soaron/'}
                        target={'_blank'}
                        rel='noreferrer'
                    >
                        <FontAwesomeIcon
                            icon={faLinkedinIn}
                            style={{ color: 'white' }}
                        />
                    </a>
                    <a
                        href={'https://twitter.com/SoaronOfficial/'}
                        target={'_blank'}
                        rel='noreferrer'
                    >
                        <FontAwesomeIcon
                            icon={faXTwitter}
                            style={{ color: 'white' }}
                        />
                    </a>
                    <a
                        href={'https://www.instagram.com/soaronofficial/'}
                        target={'_blank'}
                        rel='noreferrer'
                    >
                        <Instagram
                            className='mb-1'
                            color='#fff'
                        />
                    </a>
                </div>
            </div>

            <div className='p-4'>
                <span
                    className='text-center d-block'
                    style={{ color: 'white', fontFamily: 'poppins', fontSize: '12px' }}
                >
                    Copyright &copy; Soaron All Right Reserved
                </span>
            </div>
        </div>
    );
};

export default FooterMobileComponent;
