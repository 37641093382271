import React from "react";
import { Link } from "react-router-dom";
import jobsData from "./jobs.js";
import contents from "../../contents/index.js";
import styles from './CareerPage.module.scss';

const {
    CAREER_PAGE: { SECTION_2 },
} = contents;

export default function Flow2() {
    return (
        <div
            className="d-flex flex-column align-items-center justify-content-start"
            style={{
                backgroundColor: "#0F151A",
                height: '90vh'
            }}>
            <span
                className={`fw-bold ${styles.title}`}
                style={{
                    color: "white",
                    fontFamily: "poppins",
                }}>
                {SECTION_2.TITLE}
            </span>

            {/* box to contain both rows of input fields */}
            {/* <div
                className="d-flex flex-column align-items-center"
                style={{
                    marginBottom: '86px'
                }}
            >
                <div
                    className="d-flex align-items-center"
                    style={{
                        borderRadius: '14px',
                        backgroundColor: '#505050',
                        paddingInline: '20px',
                        paddingBlock: '10px',
                        marginBottom: '25px',
                        minHeight: '60px',
                        minWidth: '380px'
                    }}
                >
                    <div
                        className="d-flex flex-column align-items-between w-100"
                    >
                        <span
                            style={{
                                color: '#9A9A9A',
                                fontFamily: 'poppins',
                                fontSize: '12px',
                                marginBottom: '4px',
                                textAlign: 'left'
                            }}
                        >
                            Search Careers
                        </span>
                        <input
                            type={"text"}
                            style={{
                                color: '#C2C2C2',
                                fontFamily: 'poppins',
                                fontSize: '16px',
                                border: 'none',
                                outline: 'none',
                                backgroundColor: 'transparent',
                            }}
                        />
                    </div>
                    <FontAwesomeIcon
                        icon={faSearch}
                        color={'#C2C2C2'}
                        style={{
                            width: '15px',
                            height: '15px',
                        }}
                    />
                </div>
                <div
                    className="d-flex align-items-center"
                    style={{
                        gap: '24px'
                    }}
                >
                    <div
                        className="d-flex align-items-center"
                        style={{
                            borderRadius: '14px',
                            backgroundColor: '#505050',
                            paddingInline: '20px',
                            paddingBlock: '10px',
                            marginBottom: '63px',
                            minHeight: '60px',
                            minWidth: '380px'
                        }}
                    >
                        <div
                            className="d-flex flex-column align-items-start w-100"
                        >
                            <span
                                style={{
                                    color: '#C2C2C2',
                                    fontFamily: 'poppins',
                                    fontSize: '12px',
                                    marginBottom: '4px'
                                }}
                            >
                                Departments
                            </span>
                        </div>
                        <FontAwesomeIcon
                            icon={faAngleDown}
                            color={'#C2C2C2'}
                            style={{
                                width: '15px',
                                height: '15px',
                            }}
                        />
                    </div>

                    <div
                        className="d-flex align-items-center"
                        style={{
                            borderRadius: '14px',
                            backgroundColor: '#505050',
                            paddingInline: '20px',
                            paddingBlock: '10px',
                            marginBottom: '63px',
                            minHeight: '60px',
                            minWidth: '380px'
                        }}
                    >
                        <div
                            className="d-flex flex-column align-items-start w-100"
                        >
                            <span
                                style={{
                                    color: '#C2C2C2',
                                    fontFamily: 'poppins',
                                    fontSize: '12px',
                                    marginBottom: '4px'
                                }}
                            >
                                Position Type
                            </span>
                        </div>
                        <FontAwesomeIcon
                            icon={faAngleDown}
                            color={'#C2C2C2'}
                            style={{
                                width: '15px',
                                height: '15px',
                            }}
                        />
                    </div>

                    <div
                        className="d-flex align-items-center"
                        style={{
                            borderRadius: '14px',
                            backgroundColor: '#505050',
                            paddingInline: '20px',
                            paddingBlock: '10px',
                            marginBottom: '63px',
                            minHeight: '60px',
                            minWidth: '380px'
                        }}
                    >
                        <div
                            className="d-flex flex-column align-items-start w-100"
                        >
                            <span
                                style={{
                                    color: '#C2C2C2',
                                    fontFamily: 'poppins',
                                    fontSize: '12px',
                                    marginBottom: '4px'
                                }}
                            >
                                Location
                            </span>
                        </div>
                        <FontAwesomeIcon
                            icon={faAngleDown}
                            color={'#C2C2C2'}
                            style={{
                                width: '15px',
                                height: '15px',
                            }}
                        />
                    </div>
                </div>
            </div> */}

            {/* box to contain header and actual list of job openings */}
            <div className="container d-flex flex-column align-items-center">
                {/* <span
                    className="fw-bold text-left w-100"
                    style={{
                        color: 'white',
                        fontFamily: "poppins",
                        fontSize: "48px",
                        marginBottom: '56px'
                    }}
                >
                    OPEN POSITIONS
                </span> */}

                {/* table for employment details */}
                <div className="d-flex flex-column w-100 px-3">
                    {/* table header */}
                    <div
                        className="d-flex"
                        style={{
                            marginBottom: "40px",
                        }}>
                        <span
                            className="fw-bold text-start w-100 h6"
                            style={{
                                color: "white",
                                fontFamily: "poppins",
                                // fontSize: "24px",
                            }}>
                            {SECTION_2.COLUMNS[0]}
                        </span>
                        <span
                            className="fw-bold text-center w-100 h6"
                            style={{
                                color: "white",
                                fontFamily: "poppins",
                                // fontSize: "24px",
                            }}>
                            {SECTION_2.COLUMNS[1]}
                        </span>
                        <span
                            className="fw-bold text-end w-100 h6"
                            style={{
                                color: "white",
                                fontFamily: "poppins",
                                // fontSize: "24px",
                            }}>
                            {SECTION_2.COLUMNS[2]}
                        </span>
                    </div>

                    {/* table body */}
                    {jobsData.map((job) => {
                        return (
                            <div
                                key={job.id}
                                className="d-flex"
                                style={{
                                    borderBottom: "1px solid #707070",
                                    paddingBlock: "22px",
                                }}>
                                <span
                                    className="text-start w-100"
                                    style={{
                                        fontFamily: "poppins",
                                        fontSize: "16px",
                                    }}>
                                    <Link
                                        to={`/career/jobs/${job.id}`}
                                        style={{ color: "#C2C2C2", textDecoration: 'underline' }}>
                                        {job.title}
                                    </Link>
                                </span>

                                <span
                                    className="text-center w-100"
                                    style={{
                                        color: "#C2C2C2",
                                        fontFamily: "poppins",
                                        fontSize: "16px",
                                    }}>
                                    {job.location}
                                </span>
                                <span
                                    className="text-end w-100"
                                    style={{
                                        color: "#C2C2C2",
                                        fontFamily: "poppins",
                                        fontSize: "16px",
                                    }}>
                                    {job.type}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
