import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import CareerPage from "./pages/CareerPage/CareerPage";
import LandingPage from "./pages/LandingPage/LandingPage";
import StoryPage from "./pages/StoryPage/StoryPage";
import NavbarComponent from "./components/NavbarComponent/NavbarComponent";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import ContactPage from "./pages/ContactPage/ContactPage";
import { ROUTES } from "./constants/constants";
import ContentPage from "./pages/ContentPage/ContentPage";
import PatentAndTrademarkPage from "./pages/PatentAndTrademarkPage/PatentAndTrademarkPage";
import PartnerPage from "./pages/PartnerPage/PartnerPage";
import CommingSoon from "./pages/CommingSoon/CommingSoon";

const {
  HOME,
  CAREER,
  CAREER_JOBS,
  CAREER_JOBS_ID,
  STORY,
  CONTACT_US,
  NOT_FOUND,
  TERMS_AND_CONDITIONS,
  PARTNER_WITH_US,
  PRIVACY_POLICY,
  REFUND_POLICY,
  PRESS_OR_MEDIA,
  HELP_AND_SUPPORT,
  PATENT_AND_TRADEMARK,
} = ROUTES

const App = () => {
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = React.useState(window.location.pathname);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      {/* <HiringPopup /> */}
      <div className="d-flex flex-column min-vh-100" >
        <NavbarComponent currentPath={currentPath} setCurrentPath={setCurrentPath} />
        <Routes>
          <Route path={HOME} element={<LandingPage />} />
          <Route path={CAREER} element={<CareerPage flow={1} />} />
          <Route path={CAREER_JOBS} element={<CareerPage flow={2} />} />
          <Route path={CAREER_JOBS_ID} element={<CareerPage flow={3} />} />
          <Route path={STORY} element={<StoryPage />} />
          <Route path={PARTNER_WITH_US} element={<PartnerPage />} />
          <Route path={PRESS_OR_MEDIA} element={<CommingSoon />} />
          <Route path={HELP_AND_SUPPORT} element={<ContactPage />} />
          <Route path={PATENT_AND_TRADEMARK} element={<PatentAndTrademarkPage />} />
          <Route path={TERMS_AND_CONDITIONS} element={<ContentPage page={TERMS_AND_CONDITIONS} />} />
          <Route path={PRIVACY_POLICY} element={<ContentPage page={PRIVACY_POLICY} />} />
          <Route path={REFUND_POLICY} element={<ContentPage page={REFUND_POLICY} />} />
          <Route path={CONTACT_US} element={<ContactPage />} />
          <Route path={NOT_FOUND} element={<NOT_FOUND />} />
        </Routes>
        <FooterComponent setCurrentPath={setCurrentPath} />
      </div>
    </>
  );
};

export default App;
