import { Box, Typography } from '@mui/material';
import React from 'react';
import ContentBox from '../../components/ContentBox';
import messages from '../../contents';
import './LandingPage.css';
import styles from './LandingPage.module.scss';

const {
    LANDING_PAGE: { SECTION_1, SECTION_2, SECTION_3, SECTION_4, SECTION_5, SECTION_6, SECTION_7, SECTION_8 },
} = messages;

const LandingPage = () => {
    return (
        <>
            {/* before navbar header */}
            {/* <Box display={"flex"} justifyContent={"center"} padding={"20px"}>
            <span className={styles.introText} style={{ fontFamily: "poppins" }}>
               Easy, effective, quick, and accessible <br className="d-md-none" />
               <span style={{ fontWeight: 600 }}>Drone Booking</span> on the{" "}
               <a
                  style={{ fontWeight: 600 }}
                  href={"https://fly.soaron.co"}
                  color={"#0F5FC8"}
               >
                  'Fly By Soaron'.
               </a>
            </span>
         </Box> */}

            {/* 1st section */}
            <Box
                className={styles.firstSectionContainer}
                overflow={'hidden'}
                display={'flex'}
                flexDirection={'column'}
                gap={'20px'}
                alignItems={'center'}
                sx={{
                    backgroundColor: '#000000',
                }}
            >
                <div className='h-25 d-md-none'></div>
                <video
                    autoPlay='autoplay'
                    playsInline='playsinline'
                    muted={true}
                    alt=''
                    src={'assets/HeroComp.mp4'}
                    style={{ maxWidth: '800px', width: '85%', transform: 'scale(1.4)' }}
                    className={styles.heroBackground}
                />
                <div style={{ textAlign: 'center' }}>
                    <Typography
                        className={styles.firstSectionText1}
                        color={'white'}
                        fontFamily={'poppins'}
                        style={{ opacity: 0.8 }}
                        marginBottom={'2vh'}
                    >
                        {SECTION_1.SUBTITLE}
                    </Typography>
                    <Typography
                        className={styles.firstSectionText2}
                        color={'white'}
                        fontFamily={'aerospace'}
                        fontWeight={'bold'}
                        lineHeight={1}
                    >
                        {SECTION_1.TITLE}
                    </Typography>
                </div>
                <br />
                <br />
            </Box>

            {/* 2nd section */}
            <Box
                className={styles.secondSectionContainer}
                display={'flex'}
                height={'95vh'}
                padding={'5vh 5vw'}
                gap={'5vw'}
                justifyContent={'space-around'}
                alignItems={'center'}
            >
                <img
                    className={styles.secondSectionImage}
                    src={'assets/Repainting.webp'}
                    alt={''}
                />
                <Typography
                    className={styles.secondSectionText}
                    fontFamily={'poppins'}
                    fontWeight={600}
                >
                    {SECTION_2.TITLE}
                </Typography>
            </Box>

            {/* 3rd section */}
            <Box
                className={styles.thirdSectionContainer}
                display={'flex'}
                alignItems={'center'}
                height={'100vh'}
            >
                <Box
                    width={'50%'}
                    className={styles.thirdSectionImageContainer}
                >
                    <Typography
                        className={styles.thirdSectionText}
                        color={'white'}
                        marginX={'auto'}
                        width={'400px'}
                        maxWidth={'100%'}
                        fontFamily={'poppins'}
                        fontSize={'32px'}
                        fontWeight={600}
                    >
                        {SECTION_3.TITLE}
                    </Typography>
                </Box>
            </Box>

            {/* 4th section */}
            <Box
                className={styles.fourthSectionContainer}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={'5vw 5vw'}
                height={'100vh'}
                gap={'20px'}
            >
                <Typography
                    className={styles.fourthSectionText}
                    fontFamily={'poppins'}
                    fontWeight={600}
                    width={'600px'}
                >
                    {SECTION_4.TITLE}
                </Typography>
                <img
                    className={styles.fourthSectionImage}
                    src={'assets/drone2.png'}
                    style={{ transform: 'scale(1.1)' }}
                    alt=''
                />
            </Box>

            {/* 5th section */}
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                marginTop={5}
                bgcolor={'#000000'}
            >
                <Box
                    display={'flex'}
                    width={'100%'}
                    justifyContent={'center'}
                    padding={'5vh 5vw'}
                >
                    <Typography
                        className={styles.fifthSectionText}
                        fontFamily={'poppins'}
                        fontWeight={600}
                        textAlign={'center'}
                        width={'1200px'}
                        maxWidth={'90%'}
                        color={'white'}
                    >
                        {SECTION_5.TITLE}
                    </Typography>
                </Box>

                <video
                    autoPlay='autoplay'
                    playsInline='playsinline'
                    muted={true}
                    alt=''
                    src={'assets/arm.mp4'}
                    style={{ maxWidth: '1000px', width: '100%' }}
                    className={styles.heroBackground}
                />
            </Box>

            <Box
                position={'relative'}
                overflow={'hidden'}
            >
                {/* box for gray background at some distance from top */}
                <Box
                    position={'absolute'}
                    bgcolor={'#efefef'}
                    width={'100%'}
                    className={styles.sixthSectionContainer}
                    height={'100%'}
                    zIndex={-5}
                />

                {/* 6th section */}
                <Box
                    display={'flex'}
                    flexDirection={'column-reverse'}
                    justifyContent={'center'}
                    minHeight={'105vh'}
                    className={'container flex-xl-row mx-2 mx-md-auto'}
                >
                    {/* left side of the container */}
                    <Box
                        width={'50%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'end'}
                        className={styles.sixthSectionLeft}
                    >
                        <Typography
                            // marginTop={200}
                            fontFamily={'aerospace'}
                            fontWeight={600}
                            fontSize={'100px'}
                            lineHeight={'120px'}
                            marginBottom={'30px'}
                            className={styles.sixthSectionHeading}
                        >
                            {SECTION_6.TITLE}
                        </Typography>
                        {/* the designer has changed font-families across devices */}
                        <Typography
                            fontFamily={window.innerWidth > 1000 ? 'poppins' : 'quicksand'}
                            fontSize={'24px'}
                            className={styles.sixthSectionText}
                        >
                            {SECTION_6.DESCRIPTION}
                        </Typography>
                    </Box>

                    {/* right side of the container */}
                    <Box
                        width={'60%'}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        className={styles.sixthSectionRight}
                    >
                        <img
                            alt=''
                            src={'assets/rc-1.webp'}
                        />
                    </Box>
                </Box>

                {/* 7th section */}
                <Box
                    className={styles.seventhSectionContainer}
                    display={'flex'}
                    minHeight={'100vh'}
                    gap={'5vw'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    padding={'10vh 10vw'}
                    position={'relative'}
                >
                    {/* radial gradient for this section */}
                    <Box
                        position={'absolute'}
                        top={0}
                        left={0}
                        height={'105%'}
                        width={'100%'}
                        zIndex={-1}
                        bgcolor={'#000000'}
                        style={{
                            transform: 'skew(0, 5deg)',
                            background:
                                'transparent radial-gradient(closest-side at 50% 50%, #202A34F7 0%, #000000 100%) 0% 0% no-repeat padding-box',
                        }}
                    ></Box>
                    <div className={styles.seventhSectionLeft}>
                        {SECTION_7.CONTENTS.slice(0, 2).map((content, index) => (
                            <ContentBox key={index}
                                content={content}
                                color={'white'}
                            />
                        ))}
                    </div>
                    <div className={styles.seventhSectionCenter}>
                        <img
                            src='assets/line1.png'
                            alt=''
                            className='line1'
                        />
                        <img
                            src='assets/line2.png'
                            alt=''
                            className='line2'
                        />
                        <img
                            src='assets/line3.png'
                            alt=''
                            className='line3'
                        />
                        <img
                            src='assets/line4.png'
                            alt=''
                            className='line4'
                        />
                        <img
                            alt=''
                            src={'assets/rc-2.webp'}
                            width={'100%'}
                            className={styles.seventhSectionImage}
                        />
                    </div>
                    <div className={styles.seventhSectionRight}>
                        {SECTION_7.CONTENTS.slice(2, 4).map((content, index) => (
                            <ContentBox
                                key={index}
                                content={content}
                                color={'white'}
                            />
                        ))}
                    </div>
                </Box>

                {/* 8th section */}
                <Box
                    className={'container d-flex flex-column-reverse flex-xl-column'}
                    marginTop={'18vh'}
                >
                    {/* upper container */}
                    <Box
                        className={'d-xl-flex mx-3 mx-md-auto'}
                        style={{ gap: '8vw' }}
                        marginBottom={'10vh'}
                    >
                        {/* left */}
                        <Box
                            marginBottom={'40px'}
                            textAlign={'center'}
                        >
                            <img
                                alt=''
                                src={'assets/rc-3.webp'}
                                style={{ width: '90%' }}
                            />
                        </Box>

                        {/* right */}
                        <Box
                            className={'text-center text-xl-start w-100 mx-auto'}
                            sx={{ maxWidth: '500px' }}
                        >
                            {SECTION_8.CONTENTS.slice(0, 2).map((content, index) => (
                                <ContentBox
                                    key={index}
                                    content={content}
                                    gap
                                />
                            ))}
                        </Box>
                    </Box>

                    {/* lower container */}
                    <Box
                        className={'mx-2 mx-md-auto'}
                        sx={{ maxWidth: '800px' }}
                        marginBottom={'10vh'}
                    >
                        <Typography
                            fontFamily={'poppins'}
                            fontSize={'50px'}
                            fontWeight={600}
                            marginBottom={'20px'}
                            textAlign={'center'}
                            className={styles.eightSectionlowerHeading}
                        >
                            {SECTION_8.CONTENTS[2].TITLE}
                        </Typography>
                        <Typography
                            fontFamily={'quicksand'}
                            fontSize={'24px'}
                            textAlign={'center'}
                            className={styles.eightSectionlowerText}
                        >
                            {SECTION_8.CONTENTS[2].DESCRIPTION}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default LandingPage;
